/* Styles go here */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

#logo {
    width: 30px;
}

#menu {
    margin-bottom: 0px;
    background: #333333 !important;
}
#menu .navbar-brand {
    color: #FFFFFF;
}
#menu .navbar-nav {
    list-style: none;
    text-align: center;
}
#menu .navbar-nav .navbar-nav {
    list-style: none;
    text-align: center;
    width: 90px;
    line-height: 50px;
}
#menu .navbar-nav .dropdown {
    list-style: none;
    text-align: center;
    width: 90px;
    line-height: 50px;
}
#menu .dropdown {
    display: flex !important;
    order: 2;
    padding: 0px !important;
}
#menu .dropdown a {
    padding: 0px !important;
}
#menu .navbar-nav a {
    color: #FFFFFF;
}
#menu #tokenPrice {
    width: 150px;
}
#menu #account {
    width: 200px;
}
#menu #accountAddr {
    color: #FFFFFF;
    margin-right: 15px;
}
#subMenu {
    position: absolute;
    display: block;
    width: 100%;
    background: #111e5736;
    padding: 5px 0px;
}
#subMenu #icons {
    float: right;
    width: 150px;
    text-align: right;
    margin-right: 90px;
}
#subMenu #icons a {
    margin-left: 15px;
}
#subMenu #icons a img {
    margin-left: 1px;
    height: 15px;
}

@media (max-width: 768px) {
    #subMenu #icons {
        width: 100%;
        margin-right: 0px !important;
        text-align: center;
    }

    .nav-item .nav-link {
        width: 100%;
    }
}

#welcomeTo {
    font-size: 30px;
    text-align: center;
}

#basic-navbar-nav .navbar-nav {
    margin-left: auto;
    order: 2;
}

.ReactModal__Content {
    height: 350px;
    width: 300px;
    margin: 0 auto;
}

.aprHeader {
    text-align: center;
}
.aprHeader small {
    width: 100%;
    font-size: 20px;
}
.aprTable {
    width: 100%;
}
.aprTable thead {
    font-weight: bold;
}
.aprTableTextRight {
    text-align: right;
}

.operatorLinksCol {
    text-align: center;
}
.separatorOperatePoolContainer {
    margin: 0 10px;
}
.depositLink, .withdrawLink {
    color: #333333;
    font-weight: bold;
}
.depositLink.active, .withdrawLink.active {
    color: red !important;
}
.tokenInputContainer {
    width: 100%;
    height: 45px;
    background: #b3b7bb;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
.tokenInput {
    float: left;
    width: 70%;
    border: none;
    background: none;
    outline: none;
    text-align: right;
}
.poolNameTokenInputContainer {
    float: left;
    width: 15%;
    color: #FFFFFF;
    text-align: center;
}
.maxButtonContainer {
    float: left;
    width: 15%;
    text-align: center;
}
.maxButton {

}
.walletBalance {
    text-align: right;
}
.operationButton {
    clear: both;
}

.detailsPoolContainer {
    margin-top: 25px;
}

.aprValueContainer {
    display: inline-block;
}

.twitterEmbed {
    background: none !important;
    padding: 0px !important;
    height: auto !important;
}
.compoundButton {
    margin-top: 5px;
}

.ourTokenName {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 0px;
}
.farmsAndStaking {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 0px;
}
.tokenInYourWallet {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 0px;
}

#flipdown {
    margin: 0 auto;
}


/* COUNTDOWN */
/* THEMES */

/********** Theme: dark **********/
/* Font styles */
.flipdown.flipdown__theme-dark {
    font-family: sans-serif;
    font-weight: bold;
}
/* Rotor group headings */
.flipdown.flipdown__theme-dark .rotor-group-heading:before {
    color: #000000;
}
/* Delimeters */
.flipdown.flipdown__theme-dark .rotor-group:nth-child(n+2):nth-child(-n+3):before,
.flipdown.flipdown__theme-dark .rotor-group:nth-child(n+2):nth-child(-n+3):after {
    background-color: #151515;
}
/* Rotor tops */
.flipdown.flipdown__theme-dark .rotor,
.flipdown.flipdown__theme-dark .rotor-top,
.flipdown.flipdown__theme-dark .rotor-leaf-front {
    color: #FFFFFF;
    background-color: #151515;
}
/* Rotor bottoms */
.flipdown.flipdown__theme-dark .rotor-bottom,
.flipdown.flipdown__theme-dark .rotor-leaf-rear {
    color: #EFEFEF;
    background-color: #202020;
}
/* Hinge */
.flipdown.flipdown__theme-dark .rotor:after {
    border-top: solid 1px #151515;
}

/********** Theme: light **********/
/* Font styles */
.flipdown.flipdown__theme-light {
    font-family: sans-serif;
    font-weight: bold;
}
/* Rotor group headings */
.flipdown.flipdown__theme-light .rotor-group-heading:before {
    color: #EEEEEE;
}
/* Delimeters */
.flipdown.flipdown__theme-light .rotor-group:nth-child(n+2):nth-child(-n+3):before,
.flipdown.flipdown__theme-light .rotor-group:nth-child(n+2):nth-child(-n+3):after {
    background-color: #DDDDDD;
}
/* Rotor tops */
.flipdown.flipdown__theme-light .rotor,
.flipdown.flipdown__theme-light .rotor-top,
.flipdown.flipdown__theme-light .rotor-leaf-front {
    color: #222222;
    background-color: #DDDDDD;
}
/* Rotor bottoms */
.flipdown.flipdown__theme-light .rotor-bottom,
.flipdown.flipdown__theme-light .rotor-leaf-rear {
    color: #333333;
    background-color: #EEEEEE;
}
/* Hinge */
.flipdown.flipdown__theme-light .rotor:after {
    border-top: solid 1px #222222;
}

/* END OF THEMES */

.flipdown {
    overflow: visible;
    width: 510px;
    height: 110px;
}

.flipdown .rotor-group {
    position: relative;
    float: left;
    padding-right: 30px;
}

.flipdown .rotor-group:last-child {
    padding-right: 0;
}

.flipdown .rotor-group-heading:before {
    display: block;
    height: 30px;
    line-height: 30px;
    text-align: center;
}

.flipdown .rotor-group:nth-child(1) .rotor-group-heading:before {
    content: attr(data-before);
}

.flipdown .rotor-group:nth-child(2) .rotor-group-heading:before {
    content: attr(data-before);
}

.flipdown .rotor-group:nth-child(3) .rotor-group-heading:before {
    content: attr(data-before);
}

.flipdown .rotor-group:nth-child(4) .rotor-group-heading:before {
    content: attr(data-before);
}

.flipdown .rotor-group:nth-child(n+2):nth-child(-n+3):before {
    content: '';
    position: absolute;
    bottom: 20px;
    left: 115px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.flipdown .rotor-group:nth-child(n+2):nth-child(-n+3):after {
    content: '';
    position: absolute;
    bottom: 50px;
    left: 115px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.flipdown .rotor {
    position: relative;
    float: left;
    width: 50px;
    height: 80px;
    margin: 0px 5px 0px 0px;
    border-radius: 4px;
    font-size: 4rem;
    text-align: center;
    perspective: 200px;
}

.flipdown .rotor:last-child {
    margin-right: 0;
}

.flipdown .rotor-top,
.flipdown .rotor-bottom {
    overflow: hidden;
    position: absolute;
    width: 50px;
    height: 40px;
}

.flipdown .rotor-leaf {
    z-index: 1;
    position: absolute;
    width: 50px;
    height: 80px;
    transform-style: preserve-3d;
    transition: transform 0s;
}

.flipdown .rotor-leaf.flipped {
    transform: rotateX(-180deg);
    transition: all 0.5s ease-in-out;
}

.flipdown .rotor-leaf-front,
.flipdown .rotor-leaf-rear {
    overflow: hidden;
    position: absolute;
    width: 50px;
    height: 40px;
    margin: 0;
    transform: rotateX(0deg);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.flipdown .rotor-leaf-front {
    line-height: 80px;
    border-radius: 4px 4px 0px 0px;
}

.flipdown .rotor-leaf-rear {
    line-height: 0px;
    border-radius: 0px 0px 4px 4px;
    transform: rotateX(-180deg);
}

.flipdown .rotor-top {
    line-height: 80px;
    border-radius: 4px 4px 0px 0px;
}

.flipdown .rotor-bottom {
    bottom: 0;
    line-height: 0px;
    border-radius: 0px 0px 4px 4px;
}

.flipdown .rotor:after {
    content: '';
    z-index: 2;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 50px;
    height: 40px;
    border-radius: 0px 0px 4px 4px;
}

@media (max-width: 550px) {

    .flipdown {
        width: 312px;
        height: 70px;
    }

    .flipdown .rotor {
        font-size: 2.2rem;
        margin-right: 3px;
    }

    .flipdown .rotor,
    .flipdown .rotor-leaf,
    .flipdown .rotor-leaf-front,
    .flipdown .rotor-leaf-rear,
    .flipdown .rotor-top,
    .flipdown .rotor-bottom,
    .flipdown .rotor:after {
        width: 30px;
    }

    .flipdown .rotor-group {
        padding-right: 20px;
    }

    .flipdown .rotor-group:last-child {
        padding-right: 0px;
    }

    .flipdown .rotor-group-heading:before {
        font-size: 0.8rem;
        height: 20px;
        line-height: 20px;
    }

    .flipdown .rotor-group:nth-child(n+2):nth-child(-n+3):before,
    .flipdown .rotor-group:nth-child(n+2):nth-child(-n+3):after {
        left: 69px;
    }

    .flipdown .rotor-group:nth-child(n+2):nth-child(-n+3):before {
        bottom: 13px;
        height: 8px;
        width: 8px;
    }

    .flipdown .rotor-group:nth-child(n+2):nth-child(-n+3):after {
        bottom: 29px;
        height: 8px;
        width: 8px;
    }

    .flipdown .rotor-leaf-front,
    .flipdown .rotor-top {
        line-height: 50px;
    }

    .flipdown .rotor-leaf,
    .flipdown .rotor {
        height: 50px;
    }

    .flipdown .rotor-leaf-front,
    .flipdown .rotor-leaf-rear,
    .flipdown .rotor-top,
    .flipdown .rotor-bottom,
    .flipdown .rotor:after {
        height: 25px;
    }
}
